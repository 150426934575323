import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { SpinEmpty } from 'library';
import { EsNotesService } from 'services';
import Note from './Note';
import Header from './Header';

const IncidentSummaryNotes = ({ type }) => {
    const { id: incidentId } = useParams();
    const [notes, setNotes] = useState();
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [libraryItems, setLibraryItems] = useState([]);
    const { pathname } = useLocation();

    const isPreview = pathname.includes('preview');

    useEffect(() => {
        EsNotesService.library(type)
            .then(setLibraryItems);
    }, [type]);

    useEffect(() => {
        if (incidentId) {
            setLoading(true);
            EsNotesService.list(incidentId)
                .then(setNotes)
                .finally(() => setLoading(false));
        }
    }, [incidentId, type]);

    const updateNotes = useCallback((newNotes) => {
        setUpdating(true);
        return EsNotesService.save(incidentId, newNotes)
            .then(setNotes)
            .finally(() => setUpdating(false));
    }, [incidentId]);

    const onChange = (note, forceUpdate = false, index = -1) => {
        const before = notes.slice(0, index);
        const after = notes.slice(index + 1);
        const newNotes = [...before, note, ...after].filter(item => !!item);
        if (forceUpdate) {
            return updateNotes(newNotes);
        }
        setNotes(newNotes);
        return Promise.resolve();
    };

    return (
        <>
            <Header
                libraryItems={libraryItems}
                onChange={onChange}
                updating={updating}
                disabled={isPreview}
            />
            <SpinEmpty spinning={loading} items={notes}>
                <div className="notes">
                    {notes?.map((note, index) => (
                        <Note
                            key={note.id}
                            note={note}
                            updating={updating}
                            libraryItems={libraryItems}
                            disabled={isPreview}
                            onChange={(value, forceUpdate) => onChange(value, forceUpdate, index)}
                        />
                    ))}
                </div>
            </SpinEmpty>
        </>
    );
};

IncidentSummaryNotes.propTypes = {
    type: PropTypes.number.isRequired,
};

export default IncidentSummaryNotes;
