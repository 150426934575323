import { Input, Select, InputAddress } from 'library';
import { USStateOptions } from 'config/states';
import { FacilityTypeOptions } from 'config';

export default [
    {
        label: 'Facility Name',
        name: ['newFacility', 'name'],
        required: true,
        component: Input
    },
    {
        label: 'Facility Type',
        name: ['newFacility', 'type'],
        required: true,
        component: Select, 
        options: FacilityTypeOptions
    },
    {
        label: 'Address',
        name: ['newFacility', 'address'],
        component: InputAddress,
        autofill: {
            city: ['newFacility', 'city'],
            state: ['newFacility', 'state'], 
            zip: ['newFacility', 'zip'], 
        }
    },
    {
        label: 'Address 2',
        name: ['newFacility', 'address2'],
        component: Input
    },
    {
        label: 'City',
        name: ['newFacility', 'city'],
        component: Input
    },
    {
        label: 'State',
        name: ['newFacility', 'state'],
        component: Select,
        options: USStateOptions,
    },
    {
        label: 'Zip',
        name: ['newFacility', 'zip'],
        component: Input
    },
    {
        label: 'Phone',
        name: ['newFacility', 'phone'],
        component: Input
    },
    {
        label: 'Fax',
        name: ['newFacility', 'fax'],
        component: Input
    },
    {
        label: 'Email',
        name: ['newFacility', 'email'],
        component: Input
    },
];
