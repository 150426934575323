import { INCIDENT_TYPE_WC } from 'config';
import api from '../api';
import transformGet from './transform.get';
// import DIRECTIVES_ALL_TYPES from './mock';

class EsDirectivesService {
    static list({ locationId, episodeTypeId = INCIDENT_TYPE_WC }) {
        const params = {
            episodeTypeId,
            // flow: flow || INCIDENT_STATE.INTAKE,
            visibility: 'internal'
        };
        return api.get(`/api/v2/locations/${locationId}/directives`, params)
            .then(({ data }) => data)
            .then(transformGet);
    }
}

export { EsDirectivesService };
