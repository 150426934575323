import axios from 'axios';
import transformer from './transformer';

const GoogleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const googleApi = axios.create({
    baseURL: 'https://maps.googleapis.com/maps/api',
    timeout: 600011,
});

class GoogleService {
    static search(filters) {
        const params = {
            key: GoogleApiKey,
            language: 'en',
            region: 'us',
            ...filters,

        };
        return googleApi.get('/geocode/json', { params })
            .then(({ data: { results } }) => {
                if (!results.length) {
                    return [];
                }
                const result = transformer(results[0]);
                if (Array.isArray(result.city)) {
                    return result.city.map((name, index) => ({
                        id: `${result.id}_${index}`,
                        city: name,
                        state: result.state,
                        zip: result.zip,
                    }));
                }
                return [result];
            });
    }

    static autocomplete(input) {
        return googleApi.get('/place/autocomplete/json', {
            key: GoogleApiKey,
            language: 'en',
            region: 'us',
            input
        });
    }

    static searchByZip(zip) {
        const params = {
            components: `postal_code:${zip}|country:US`
        };
        return GoogleService.search(params)
            .then(data => data.filter(item => item.state && item.city));
    }

    static transform(data) {
        return transformer(data);
    }
}

export { GoogleService };
