import { transformTableParams } from 'utils';
import api from '../api';
import transformList from './transformer.list';
import transformUpdate from './transformer.update';
import transformCreate from './transformer.create';
import transformGet from './transformer.get';

class EsIncidentsService {
    static async get(incidentId) {
        return api.get(`/api/v2/incidents/${incidentId}`)
            .then(({ data }) => data)
            .then(transformGet);
    }

    static list(args) {
        return api.get('/api/v2/incidents', transformTableParams(args, true))
            .then(transformList);
    }

    static search(filters) {
        return api.post('/api/v2/incidents/searches', filters)
            .then(transformList);
    }

    static find(filters) {
        return this.list({
            page: 1,
            perPage: 1,
            filters
        })
            .then((result) => {
                if (result?.data?.length) {
                    return result.data[0];
                }
                return undefined;
            });
    }

    static create(values) {
        return api.post('/api/v2/incidents', transformCreate(values))
            .then(({ data }) => data)
            .then(transformGet);
    }

    static update(id, values) {
        const postData = transformUpdate(values);
        return api.put(`/api/v2/incidents/${id}`, postData)
            .then(({ data }) => data)
            .then(transformGet);
    }

    static patch(id, values) {
        return api.put(`/api/v2/incidents/${id}`, values)
            .then(({ data }) => data)
            .then(transformGet);
    }

    static clone(id, values) {
        return api.post(`/api/v2/incidents/${id}`, values)
            .then(({ data }) => data)
            .then(transformGet);
    }

    static void(id) {
        return api.delete(`/api/v2/incidents/${id}`);
    }
}

export { EsIncidentsService };
