import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { GoogleService } from 'services';
import { catchError } from 'utils';
import { AutoComplete } from '../AutoComplete';
import { InputText } from '../InputText';

const InputZip = ({ value, name, onChange, ...rest }) => {
    const [thisValue, setThisValue] = useState();
    const form = Form.useFormInstance();
    const autofillKey = name?.substring(0, name?.indexOf('_zip'));

    useEffect(() => {
        setThisValue(value);
    }, [value]);

    const onBeforeChange = (val, item) => {
        if (item && item.state && autofillKey) {
            form.setFieldValue(['fields', `${autofillKey}_city`], { value: item.city });
            form.setFieldValue(['fields', `${autofillKey}_state`], { value: item.state });
            onChange(item.zip);
        } else if (val) {
            onChange(val.toString().replace(/\D/g, ''));
        } else {
            onChange(val);
        }
    };

    const fetchOptions = ({ query }) =>
        GoogleService.searchByZip(query)
            .then((data) => {
                const options = data.map(item => ({
                    key: item.id,
                    value: item.id,
                    item,
                    label: `${item.city}, ${item.state}`
                }));
                return { options };
            })
            .catch(catchError);

    if (!autofillKey) {
        return (
            <InputText
                value={thisValue}
                allowClear
                onChange={onBeforeChange}
                minLength={5}
                {...rest}
            />
        );
    }

    return (
        <AutoComplete
            value={thisValue}
            allowClear
            fetchOptions={fetchOptions}
            onChange={onBeforeChange}
            minLength={5}
            {...rest}
        />
    );
};

InputZip.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    name: PropTypes.string
};

export { InputZip };

/*
const addressResponse = await geocode(RequestType.ADDRESS, "Eiffel Tower", {
  language: "en",
  region: "sp",
});
*/
