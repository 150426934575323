import { Button, Popconfirm } from 'antd';
import PropTypes from 'prop-types';

const ButtonWithConfirm = ({ buttonProps = {}, children, ...rest }) => (
    <Popconfirm
        overlayStyle={{ maxWidth: 450 }}
        {...rest}
    >
        <Button {...buttonProps}>{children}</Button>
    </Popconfirm>
);

ButtonWithConfirm.propTypes = {
    children: PropTypes.any,
    buttonProps: PropTypes.object
};

export { ButtonWithConfirm };
