import './FieldCreateIncident.scss';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Drawer, Result, Form } from 'library';
import { EsIncidentsService } from 'services';
import { PlusCircleFilled } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { showError } from 'utils';
import { INCIDENT_TYPE_WORKPLACE_VIOLENCE } from 'config';
import { IncidentCard } from '../../IncidentCard';
import { IncidentProps } from './config';

const FieldWVCreateIncident = ({ disabled = false, showIf = {} }) => {
    const { nextId } = useParams();
    const [related, setRelated] = useState();
    const {
        id: incidentId,
        incidentTypeId,
        relatedIncident
    } = useSelector(state => state.incident);
    const navigate = useNavigate();
    const form = Form.useFormInstance();
    
    const setupRealatedIncident = useCallback(() => {
        if (relatedIncident) {
            if (!relatedIncident.submitted) {
                navigate(`/incidents/${incidentId}/intake/${relatedIncident.id}`);
            }
            return;
        }

        EsIncidentsService.clone(incidentId, {
            incidentTypeId: INCIDENT_TYPE_WORKPLACE_VIOLENCE,
            syncToId: incidentId,
        })
        .then((data) => {
            setRelated(data);
            return data.id;
        })
        .then((relatedId) => {
            EsIncidentsService.update(incidentId, {
                syncToId: relatedId,
            });
            navigate(`/incidents/${incidentId}/intake/${relatedId}`);
        })
        .catch(showError);
    }, [incidentId, relatedIncident, navigate]);

    const fieldsToWatch = showIf.all.map(item => (['fields', item.fieldName]));
    const touched = form.isFieldsTouched(fieldsToWatch);

    const checkForRelatedIncident = touched
        && (!relatedIncident || !relatedIncident.submitted)
        && !nextId
        && incidentId
        && !disabled // is not preview
        && incidentTypeId !== INCIDENT_TYPE_WORKPLACE_VIOLENCE;

    useEffect(() => {
        if (checkForRelatedIncident) {
            setupRealatedIncident();
        }
    }, [
        setupRealatedIncident,
        checkForRelatedIncident
    ]);

    if (!incidentId || !related || disabled) {
        return null;
    }

    return (
        <Drawer
            title={(
                <Result
                    icon={<PlusCircleFilled />}
                    status="success"
                    title="WV Incident was just created"
                    subTitle="A new Workplace Violence incident will be opened after completing the current WC intake. Submit it to be brought back to the current incident."
                />
            )}
            open={!!related}
            closable={false}
            footer={(
                <Button
                    type="primary"
                    block
                    onClick={() => setRelated()}
                >
                    Close
                </Button>
            )}
        >
            <IncidentCard
                incident={related}
            >
                {IncidentProps
                    .map(({ label, key, render }) => {
                        let value = related[key];
                        value = render ? render(related) : value;
                        return (
                            <React.Fragment key={key}>
                                <div> {label}</div>
                                <span>{value}</span>
                            </React.Fragment>
                        );
                    })}
            </IncidentCard>
        </Drawer>
    );
};

FieldWVCreateIncident.propTypes = {
    disabled: PropTypes.bool,
    showIf: PropTypes.object
};

export { FieldWVCreateIncident };
