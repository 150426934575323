import './EmployerContacts.scss';
import React, { useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { ORGANIZATION_CONTACT_ROLES } from 'config';
import { formatPhone } from 'utils';
import { SpinEmpty } from 'library';
import { EsLocationsService } from 'services';
import Context from 'layout/Grid/context';

const SummaryEmployerContacts = () => {
    const { locationId } = useSelector(state => state.incident);
    const { expanded } = useContext(Context);
    const [contacts, setContacts] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (locationId) {
            setLoading(true);
            EsLocationsService.contacts(locationId, {
                organizationRoleId: Object.keys(ORGANIZATION_CONTACT_ROLES)
            })
                .then(data => setContacts(data.data))
                .finally(setLoading);
        }
    }, [locationId, expanded]);

    return (
        <>
            <h2>Employer Contacts</h2>
            <SpinEmpty spinning={loading} items={contacts}>
                <div className="data-grid data-grid-contacts">
                    {contacts?.map((contact) => {
                        const fullName = `${contact.firstName || ''} ${contact.lastName || ''}`;
                        const { email, workPhone: phone, companyName, fax } = contact;
                        return (
                            <React.Fragment key={contact.roleId}>
                                <div className="double">{contact.roleName}</div>
                                <div>Name</div><span>{fullName}</span>
                                <div>Company</div><span>{companyName}</span>
                                <div>Email</div><span>{email}</span>
                                <div>Phone</div><span>{formatPhone(phone)}</span>
                                <div>Fax</div><span>{formatPhone(fax)}</span>
                                <div>Address</div>
                                <span>{contact.address1} </span>
                                <div>City, State, Zip</div>
                                <span>
                                    {contact.city}
                                    {' '}
                                    {contact.state}
                                    {' '}
                                    {contact.zip}
                                </span>
                            </React.Fragment>
                        );
                    })}
                </div>
            </SpinEmpty>
        </>
    );
};

export default SummaryEmployerContacts;
