import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'library';
import { INCIDENT_STATE } from 'config';
import { clear as clearIncident } from 'store/incident';
import { clear as clearLocation } from 'store/location';
import IncidentContext from '../../Incident/context';

const IntakePreviewFooter = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: incidentId, $updating, incidentState } = useSelector(state => state.incident);
    const incidentIntakePath = `/incidents/${incidentId}/intake`;
    const { update } = useContext(IncidentContext);
    const isVoid = incidentState === INCIDENT_STATE.VOID;

    const onPreviewUpdateClick = () => {
        if (incidentState === INCIDENT_STATE.INTAKE) {
            navigate(incidentIntakePath);
        } else {
            update({ incidentState: INCIDENT_STATE.INTAKE })
                .then(() => navigate(incidentIntakePath));
        }
    };
    const onPreviewCancel = () => {
        dispatch(clearLocation());
        dispatch(clearIncident());
        navigate('/');
    };

    return (
        <footer>
            <Button
                onClick={onPreviewCancel}
                type="link"
            >
                Cancel
            </Button>
            <Button
                type="primary"
                onClick={onPreviewUpdateClick}
                disabled={!incidentId || isVoid}
                loading={$updating}
            >
                Update
            </Button>
        </footer>
    );
};

export default IntakePreviewFooter;
