import React, { useContext, useEffect, useState } from 'react';
import { ButtonWithConfirm } from 'library';
import { useSelector } from 'react-redux';
import IncidentContext from 'routes/Incident/context';

const Actions = {
    VOID_LAST_CALL: 'VOID_LAST_CALL',
    VOID_INCIDENT: 'VOID_INCIDENT',
    CANCEL_CALLBACK: 'CANCEL_CALLBACK'
};

const Wording = {
    VOID_LAST_CALL: {
        label: 'Cancel Update',
        description: 'Are you sure you want to Cancel this Update?'
    },
    VOID_INCIDENT: {
        label: 'Void Incident',
        description: 'Are you sure you want to mark this Incident as Void?'
    },
    CANCEL_CALLBACK: {
        label: 'Void Incident',
        description: (
            <>
                Cancelling this intake will also cancel an associated callback. 
                Only continue if the callback for this incident should also be cancelled. 
                <div>Are you sure you want to continue?</div>
            </>
        )
    }
};

const IntakeMarkVoidButton = () => {
    const { incidentId, _void } = useContext(IncidentContext);
    const { calls } = useSelector(state => state.incident);
    const [action, setAction] = useState(Actions.VOID_INCIDENT);

    useEffect(() => {
        if (calls?.length) {
            const [latestCall, ...otherCalls] = calls;
            const hasActiveCalls = otherCalls.filter(item => item.deleted === 0).length > 0; 
            if (latestCall.status === 'callback') {
                setAction(Actions.CANCEL_CALLBACK);
            } else if (hasActiveCalls) {
                setAction(Actions.VOID_LAST_CALL);
            } 
        }
    }, [calls]);

    return (
        <ButtonWithConfirm
            okText="Yes"
            cancelText="No"
            buttonProps={{ disabled: !incidentId }}
            type="link"
            onConfirm={() => _void()}
            description={Wording[action].description}
        >
            {Wording[action].label}
        </ButtonWithConfirm>
    );
};

export default IntakeMarkVoidButton;
