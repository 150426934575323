import React, { useContext, useEffect } from 'react';
import { Form, Spin, Fieldset } from 'library';
import { useSelector } from 'react-redux';
import { Rules } from 'utils';
import { INCIDENT_STATE } from 'config';
import IncidentDone from '../../Incident/IncidentDone';
import { InjurySurveyQuestions } from './config';
import IncidentContext from '../../Incident/context';
import ReportOnlyReasonSelect from './fields/ReportOnlyReason';
import Footer from '../Footer';

const NoTriageSummaryForm = () => {
    const incident = useSelector(state => state.incident);
    const [form] = Form.useForm();
    const { done, update, onDoneSuccess, patch } = useContext(IncidentContext);
    
    useEffect(() => {
        if (incident.id) {
            form.resetFields();
        }
    }, [incident.id, form]);

    const onFinish = (vals) => {
        update({
            ...vals,
            incidentState: INCIDENT_STATE.DONE
        }).then(onDoneSuccess);
    };

    const fields = InjurySurveyQuestions.map(item => ({ 
        ...item, 
        onBlur: e => patch({ fields: { [item.name]: { value: e.target.value } } }) 
    }));

    if (done) {
        return <IncidentDone />;
    }

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
            className="container"
            initialValues={incident}
        >
            <Spin spinning={!!incident.$loading}>

                <Fieldset title="Report Only Reason">
                    <Form.Item
                        name="reportOnlyReason"
                        className="required"
                        rules={[Rules.required]}
                    >
                        <ReportOnlyReasonSelect 
                            onChange={val => patch({ reportOnlyReason: val })}
                        />
                    </Form.Item>
                </Fieldset>
                <Fieldset
                    id="injury-questions"
                    title="Injury Questions"
                    namePrefix="fields"
                    fields={fields}
                    allowClear
                />
                <Footer />
            </Spin>
        </Form>
    );
};

export default NoTriageSummaryForm;
