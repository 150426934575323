import { QUESTION_TYPE_MAP } from 'config';
import { transformOptions } from '../questions/transform.list';

const transformList = ({ data }) => data.map(item => ({
    ...item,
    label: item.title,
    options: transformOptions(item),
    type: (QUESTION_TYPE_MAP[item.type] || 'TEXT'),
    required: !!item.required,
    isColored: !!item.isColored
}));

export default transformList;
