import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Fieldset, Select, Input, InputPhoneWithCountry } from 'library';
import { LanguageOptions, DISTRIBUTION_MODE } from 'config';
import { Rules } from 'utils';

import { LookupLocation, LookupLocationExternal } from 'components/lookups';
import { SelectIncidentType } from 'components/SelectIncidentType';

import Context from '../context';

export const SELF_REPORT_VALUES = {
    YES: 1,
    NO: 2,
    NO_THE_SUPERVISOR: 3
};
const selfReportOptions = [
    {
        label: 'Yes',
        value: SELF_REPORT_VALUES.YES
    },
    {
        label: 'No',
        value: SELF_REPORT_VALUES.NO
    },
    {
        label: 'No, the Supervisor',
        value: SELF_REPORT_VALUES.NO_THE_SUPERVISOR
    }
];

const CallerIdForm = () => {
    const { interpreter, topLocation, parentLoading } = useContext(Context);
    const call = useSelector(state => state.call);
    const [selfReportValue, setSelfReportValue] = useState(SELF_REPORT_VALUES.YES);
    const selfReportRules = selfReportValue === SELF_REPORT_VALUES.YES ? [Rules.required] : [];
    
    return (
        <>
            <Fieldset>
                {topLocation && (
                    <Form.Item
                        name="identifiedLocation"
                        label="Call-Identified Location"
                    >
                        <Input disabled />
                    </Form.Item>
                )}
                <LookupLocation
                    defaultFilters={{
                        assignedPhoneNumber: call.toPhone,
                        clientCode: call.locationSearchCode,
                        titleElastic: call.locationTitle,
                        distributionMode: DISTRIBUTION_MODE.LIVE,
                    }}
                    loading={parentLoading}
                    disabled={parentLoading}
                />
                <LookupLocationExternal />
                {/* Holds External Location values */}
                <Form.Item
                    name="fields"
                    noStyle
                >
                    <Input style={{ display: 'none' }} />
                </Form.Item>
                <Form.Item
                    name="episodeTypeId"
                    label="Incident type"
                    rules={[Rules.required]}
                    className="required"
                >
                    <SelectIncidentType
                        isNew
                        placeholder="Required"
                    />
                </Form.Item>
            </Fieldset>

            <Fieldset>
                <Form.Item
                    label="Are you reporting for yourself?"
                    name="selfReportValue"
                    rules={[Rules.required]}
                >
                    <Select
                        options={selfReportOptions}
                        onChange={setSelfReportValue}
                    />
                </Form.Item>
                <Form.Item
                    name="callerPhone"
                    label="Callback Number"
                    className="required"
                    rules={[Rules.required]}
                >
                    <InputPhoneWithCountry
                        placeholder="Required"
                    />
                </Form.Item>

                <Form.Item
                    name="callerFirstName"
                    label="Caller First Name"
                    className="required"
                    rules={selfReportRules}
                >
                    <Input
                        placeholder={
                            selfReportValue === SELF_REPORT_VALUES.YES
                                ? 'Required' : ''
                        }
                    />
                </Form.Item>

                <Form.Item
                    name="callerLastName"
                    label="Caller Last Name"
                    className="required"
                    rules={selfReportRules}
                >
                    <Input
                        placeholder={
                            selfReportValue === SELF_REPORT_VALUES.YES
                                ? 'Required' : ''
                        }
                    />
                </Form.Item>
                <Form.Item
                    name="claimantLanguageCode"
                    label="Language"
                    className="required"
                    rules={[Rules.required]}
                >
                    <Select
                        placeholder="Select a Language"
                        options={LanguageOptions}
                    />
                </Form.Item>

                <Form.Item
                    name="interpreterId"
                    label="Interpreter Id"
                    rules={interpreter ? [Rules.required] : []}
                >
                    <Input />
                </Form.Item>
            </Fieldset>
        </>
    );
};

export default CallerIdForm;
