import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formatPhone } from 'utils';
import { Modal, Radio } from 'library';
import { useSelector, useDispatch } from 'react-redux';
import { transferToPhone } from 'store/call';

const TransferToInsuranceModal = ({ contacts, setOpen }) => {
    const call = useSelector(state => state.call);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [value, setValue] = useState();
    
    useEffect(() => {
        if (contacts.length) {
            setValue(contacts[0].phone);
        }
    }, [contacts]);

    useEffect(() => {
        if (!call.id) {
            setLoading(false);
        }
    }, [dispatch, call.id]);

    const transfer = () => {
        setLoading(true);
        dispatch(transferToPhone(value))
            .unwrap()
            .then(() => {
                setOpen(false);
            })
            .finally(() => setLoading(false));
    };

    return (

        <Modal
            className="warning modal-choose-to-continue"
            title="Transfer To Insurance"
            open
            onOk={transfer}
            confirmLoading={loading}
            onCancel={() => setOpen(false)}
            cancelButtonProps={{ type: 'text' }}
            closable={false}
            centered
        >
            <h2>Insurance Personnel</h2>
            <div className="content">
                <Radio.Group
                    onChange={e => setValue(e.target.value)}
                    value={value}
                >
                    {contacts.map(item => (
                        <Radio
                            key={item.id}
                            value={item.phone}
                            className={value === item.phone ? 'active' : ''}
                        >
                            <div>
                                <span>
                                    {item.firstName}
                                    {' '}
                                    {item.lastName}
                                </span>
                                <div>
                                    {item.companyName}
                                </div>
                            </div>
                            <div>
                                {formatPhone(item.phone)}
                                <div>{item.phoneType}</div>
                            </div>
                        </Radio>
                    ))}
                </Radio.Group>
            </div>
        </Modal>
    );
};

TransferToInsuranceModal.propTypes = {
    contacts: PropTypes.array.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default TransferToInsuranceModal;
