const cityTypes = ['locality', 'sublocality_level_1', 'neighborhood'];
const stateTypes = ['administrative_area_level_1'];
const zipTypes = ['postal_code'];
const streetTypes = ['route'];

const getProp = (comps, types) => {
    let item;
    types.forEach((type) => {
        if (!item) {
            item = comps.find(comp => comp.types.includes(type));
        }
    });
    return item?.short_name || '';
};

const transformGoogleAddress = (item) => {
    const {
        place_id: placeId,
        address_components: components,
        postcode_localities: cities,
        formatted_address: formattedAddress,
    } = item;

    const state = getProp(components, stateTypes);
    const city = cities?.length ? cities : getProp(components, cityTypes);
    const zip = getProp(components, zipTypes);
    const street = getProp(components, streetTypes);
    const address = street ? formattedAddress.substr(0, formattedAddress.indexOf(',')) : undefined;

    return {
        id: placeId,
        address,
        city,
        state,
        zip,
    };
};

export default transformGoogleAddress;
