import {
    InputPhone, Checkbox, InputText, Select,
    InputDateTime, InputDate, InputTime, InputZip, InputAddressES,
    InputBool, TextArea, AutoCompleteES, Confirm,
} from 'library';
import dayjs from 'dayjs';
import {
    FieldWVCreateIncident,
    FieldInjurySurvey,
    FieldSsn
} from 'components/fields';

const FIELD_TYPE_AUTOCOMPLETE = 1;
const FIELD_TYPE_DATE = 2;
const FIELD_TYPE_YES_NO = 3;
const FIELD_TYPE_CHECKBOX = 4;
const FIELD_TYPE_DROPDOWN = 5;
const FIELD_TYPE_TIME = 6;
const FIELD_TYPE_CHECKBOX_ARRAY = 7;
const FIELD_TYPE_MEMO = 8;
const FIELD_TYPE_ALERT = 9;
const FIELD_TYPE_ACKNOWLEDGE_ALERT = 10;
const FIELD_TYPE_PLAIN_TEXT = 11;
const FIELD_TYPE_DATE_AUTOFORMAT = 12;
const FIELD_TYPE_DATE_TIME = 13;
export const FIELD_TYPE_ACTION_CREATE_WV_INCIDENT = 114;

export const QUESTION_TYPE_MAP = {
    [FIELD_TYPE_AUTOCOMPLETE]: 'LOOKUP',
    [FIELD_TYPE_DATE]: 'DATE',
    [FIELD_TYPE_YES_NO]: 'YESNO',
    [FIELD_TYPE_CHECKBOX]: 'CHECKBOX',
    [FIELD_TYPE_DROPDOWN]: 'SELECT',
    [FIELD_TYPE_TIME]: 'TIME',
    [FIELD_TYPE_CHECKBOX_ARRAY]: 'CHECKBOXES',
    [FIELD_TYPE_MEMO]: 'TEXTAREA',
    [FIELD_TYPE_ALERT]: 'ALERT',
    [FIELD_TYPE_ACKNOWLEDGE_ALERT]: 'ALERT',
    [FIELD_TYPE_PLAIN_TEXT]: 'TEXT',
    [FIELD_TYPE_DATE_AUTOFORMAT]: 'DATE',
    [FIELD_TYPE_DATE_TIME]: 'DATETIME',
    [FIELD_TYPE_ACTION_CREATE_WV_INCIDENT]: 'ACTION_CREATE_WV_INCIDENT'
};

export const INJURY_SURVEY_QUESTIONS = [
    'injury_description',
    'injury_nature_of',
    'injury_object_involved',
    'injury_activity_description',
    'injury_pertinent_medical_history'
];

const QUESTION_TYPE = Object.values(QUESTION_TYPE_MAP)
    // eslint-disable-next-line no-sequences
    .reduce((acc, curr) => (acc[curr] = curr, acc), {});

export const getField = ({
    name, type, options, maxLength, fetch, placeholder, isDirective, ...rest
}) => {
    if (name?.endsWith('_zip')) {
        return (
            <InputZip
                name={name}
                placeholder={placeholder}
            />
        );
    }

    if (['claimant_address', 'injury_address'].includes(name)) {
        return (
            <InputAddressES
                name={name}
                placeholder={placeholder}
            />
        );
    }

    if (INJURY_SURVEY_QUESTIONS.includes(name)) {
        return (
            <FieldInjurySurvey
                name={name}
                placeholder={placeholder}
            />
        );
    }

    if (name?.endsWith('_phone') || name?.endsWith('_fax')) {
        return (
            <InputPhone name={name} />
        );
    }

    if (name?.endsWith('_ssn')) {
        return (
            <FieldSsn />
        );
    }

    if (fetch && !options?.length) {
        return (
            <AutoCompleteES
                fetch={fetch}
                placeholder={placeholder}
            />
        );
    }

    if ([QUESTION_TYPE.LOOKUP, QUESTION_TYPE.SELECT].includes(type) && Array.isArray(options)) {
        return (
            <Select
                options={options}
                placeholder={placeholder}
                allowClear
            />
        );
    }

    switch (type) {
        case QUESTION_TYPE.ACTION_CREATE_WV_INCIDENT: {
            return (
                <FieldWVCreateIncident {...rest} />
            );
        }
        case QUESTION_TYPE.ALERT: {
            return <Confirm />;
        }
        case QUESTION_TYPE.YESNO: {
            if (isDirective) {
                return (
                    <Select
                        allowClear
                        options={[
                            { value: 'No', label: 'No' },
                            { value: 'Yes', label: 'Yes' }
                        ]}
                        placeholder={placeholder}
                    />
                );
            }
            return (
                <Select
                    allowClear
                    options={[
                        { value: '1', label: 'No' },
                        { value: '2', label: 'Yes' }
                    ]}
                    placeholder={placeholder}
                />
            );
        }
        case QUESTION_TYPE.CHECKBOX:
            return <InputBool />;
        case QUESTION_TYPE.CHECKBOXES:
            return (
                <Checkbox.Group
                    options={options}
                    placeholder={placeholder}
                />
            );
        case QUESTION_TYPE.DATE: {
            const disabledDatesFn = current => current && current > dayjs().add(1, 'day');

            return (
                <InputDate
                    placeholder={placeholder}
                    disabledDate={disabledDatesFn}
                />
            );
        }
        case QUESTION_TYPE.TIME: {
            return (
                <InputTime
                    placeholder={placeholder}
                />
            );
        }
        case QUESTION_TYPE.DATETIME:
            return (
                <InputDateTime
                    placeholder={placeholder}
                />
            );
        case QUESTION_TYPE.TEXTAREA: {
            return (
                <TextArea
                    rows={8}
                    placeholder={placeholder}
                />
            );
        }
        /* case QUESTION_TYPE.SELECT: {
            return fetch ? (
                <SelectRemote
                    fetch={fetch}
                    placeholder={placeholder}
                />
            ) : (
                <Select
                    options={options}
                    placeholder={placeholder}
                    allowClear
                />
            );
        }

        case QUESTION_TYPE.LOOKUP: {
            return fetch ? (
                <AutoComplete
                    fetch={fetch}
                    placeholder={placeholder}
                />
            ) : (
                <InputText
                    maxLength={maxLength || 1000}
                    placeholder={placeholder}
                />
            );
        } */
        case QUESTION_TYPE.TEXT:
        default:
            return (
                <InputText
                    maxLength={maxLength || 1000}
                    placeholder={placeholder}
                />
            );
    }
};
