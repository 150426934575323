export const DISTRIBUTION_MODE = {
    TEST: 1, 
    BETA: 2, 
    LIVE: 3, 
    OFF: 4, 
};

export const DISTRIBUTION_MODE_MAP = {
    [DISTRIBUTION_MODE.TEST]: 'Test',
    [DISTRIBUTION_MODE.BETA]: 'Beta',
    [DISTRIBUTION_MODE.LIVE]: 'Active',
    [DISTRIBUTION_MODE.OFF]: 'Off',
};

export const CONTACT_ROLE_PRIMARY_CONTACT = 20;
export const CONTACT_ROLE_INS_CARRIER_TPA = 28;
export const CONTACT_ROLE_RETURN_TO_WORK_COORDINATOR = 23;
export const CONTACT_ROLE_CN_ACCOUNT_EXECUTIVE = 24;

export const ORGANIZATION_CONTACT_ROLES = {
    // [CONTACT_ROLE_PRIMARY_CONTACT]: 'Primary Contact',
    [CONTACT_ROLE_INS_CARRIER_TPA]: 'Insurance Carrier/TPA',
    [CONTACT_ROLE_RETURN_TO_WORK_COORDINATOR]: 'Return To Work Coordinator',
    [CONTACT_ROLE_CN_ACCOUNT_EXECUTIVE]: 'CN Account Executive'
};

export const SSN_SHOW_ALL = 9;
export const SNN_SHOW_LAST_FOUR = 4;
