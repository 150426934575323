import './Greeting.scss';
import React, { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Skeleton } from 'library';
import { getIncidentType } from 'config';
import Context from '../context';

const CallerIdGreeting = () => {
    const { loading, topLocation } = useContext(Context);
    const form = Form.useFormInstance();
    const call = useSelector(state => state.call);
    const location = useSelector(state => state.location);
    const [greeting, setGreeting] = useState('');
    const _greetings = location?.specialGreeting || topLocation?.specialGreeting;
    const incidentTypeId = Form.useWatch('episodeTypeId', form);

    useEffect(() => {
        if (_greetings) {
            const incidentType = getIncidentType(incidentTypeId)?.title || '';
            const result = _greetings
                .replaceAll('{LocationName}', topLocation?.title || '<div class="tag">[LocationName]</div>')
                .replaceAll('{IncidentTypeTitle}', incidentType || '<div class="tag">{IncidentTypeTitle}</div>');

            setGreeting(result);
        }
    }, [_greetings, incidentTypeId, topLocation?.title]);

    return (
        <Form.Item label="Special Greetings">
            <Skeleton
                paragraph={{ rows: 3 }}
                title={false}
                active
                loading={loading}
                className="greetings-skeleton"
            >
                <div className="greetings">
                    <div dangerouslySetInnerHTML={{ __html: greeting }} />
                    <div>
                        {call.isExistingIncident
                            ? 'I see you`re calling in regards to an existing incident. Is that correct?'
                            : 'I see you`re calling to report a new incident. Is that correct?'}
                    </div>
                </div>
            </Skeleton>

        </Form.Item>
    );
};

export default CallerIdGreeting;
