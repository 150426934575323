import './InputAddress.scss';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { SearchOutlined } from '@ant-design/icons';
import { GoogleService } from 'services';
import { Input } from '../AntD';

const AutocompleteOptions = {
    componentRestrictions: { country: 'us' },
    fields: ['address_components', 'formatted_address', 'icon', 'vicinity', 'place_id', 'types'],
};

const InputAddress = ({ value, onChange, autofill = {}, autofillFn, ...rest }) => {
    const form = Form.useFormInstance();

    const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
    const inputRef = useRef(null);
    const places = useMapsLibrary('places');

    useEffect(() => {
        if (places && inputRef.current) {
            const { input } = inputRef.current;
            const autoComplete = new places.Autocomplete(input, AutocompleteOptions);
            setPlaceAutocomplete(autoComplete);
        }
    }, [places]);

    const onSelect = useCallback((item) => {
        if (item) {
            if (autofill) {
                Object.keys(autofill).forEach((key) => {
                    const field = autofill[key];
                    let val = item[key];
                    if (autofillFn) {
                        val = autofillFn(val);
                    }
                    form.setFieldValue(field, val);
                });
            }
            onChange(item.address);
        }
    }, [onChange, autofill, autofillFn, form]);

    useEffect(() => {
        if (placeAutocomplete) {
            placeAutocomplete.addListener('place_changed', () => {
                const raw = placeAutocomplete.getPlace();
                const data = GoogleService.transform(raw);
                onSelect(data);
            });
        }
    }, [onChange, onSelect, placeAutocomplete]);

    return (
        <Input
            ref={inputRef}
            autoComplete="off"
            suffix={<SearchOutlined />}
            value={value}
            onChange={onChange}
            {...rest}
        />
    );
};

InputAddress.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    autofill: PropTypes.object,
    autofillFn: PropTypes.func
};

export { InputAddress };
